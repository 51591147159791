import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import '@babylonjs/loaders/OBJ/objFileLoader';
import { FaHeart, FaBookmark, FaShare, FaLink, FaUserCircle } from 'react-icons/fa';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import debounce from 'lodash.debounce';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ModelPreviewModal = ({
  isOpen,
  onClose,
  modelUrl,
  // Legacy approach (for dynamic texture):
  images = [],
  positions = [],
  rotations = [],
  scales = [],
  // New decal approach:
  placements = [],
  useDecals = true,
  tattoo,
  isUserPage = false,
  username,
  onStateChange = () => {},
}) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const currentUserId = user?.id;

  // Babylon.js references
  const canvasRef = useRef(null);
  const engineRef = useRef(null);
  const sceneRef = useRef(null);
  const modelRef = useRef(null);
  // For legacy dynamic texture approach
  const dynamicTextureRef = useRef(null);
  // For decal approach: store decal meshes so they can be cleared
  const decalMeshesRef = useRef([]);

  // UI states
  const shareRef = useRef(null);
  const [shareVisible, setShareVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Like / Favorite states
  const [likeCount, setLikeCount] = useState(tattoo?.likes_count || 0);
  const [favoriteCount, setFavoriteCount] = useState(tattoo?.favorites_count || 0);
  const [isLiked, setIsLiked] = useState(tattoo?.isLiked || false);
  const [isFavorited, setIsFavorited] = useState(tattoo?.isFavorited || false);

  // Construct a share URL
  const uniqueTattooUrl = isUserPage
    ? `${window.location.origin}/#/userpage/${username}/${tattoo?.id}`
    : `${window.location.origin}/#/tattoo/${tattoo?.id}`;

  const { creator } = tattoo || {};

  // ---------------------------
  // Helper: Create Decal Material
  // ---------------------------
  const createDecalMaterial = (textureURL, scene) => {
    const decalMat = new BABYLON.StandardMaterial('decalMat', scene);
    decalMat.diffuseTexture = new BABYLON.Texture(textureURL, scene);
    decalMat.diffuseTexture.hasAlpha = true;
    decalMat.useAlphaFromDiffuseTexture = true;
    decalMat.needDepthPrePass = true;
    decalMat.separateCullingPass = true;
    decalMat.backFaceCulling = false;
    decalMat.transparencyMode = BABYLON.Material.MATERIAL_ALPHABLEND;
    decalMat.alphaMode = BABYLON.Engine.ALPHA_COMBINE;
    decalMat.zOffset = -1;
    return decalMat;
  };

  // ---------------------------
  // Updated Decal Approach
  // ---------------------------
  const applyDecalApproach = useCallback(() => {
    if (!sceneRef.current || !modelRef.current) return;
    // Clear old decals if any
    decalMeshesRef.current.forEach((d) => d.dispose());
    decalMeshesRef.current = [];

    // Use decal data: prefer "placements" if provided; otherwise fall back to legacy "positions"
    const decalData = placements && placements.length > 0 ? placements : positions;
    images.forEach((url, i) => {
      const data = decalData[i] || {};
      let pos, norm, rotDeg, scl;
      if (placements && placements.length > 0) {
        pos = data.position || { x: 0, y: 1, z: 0 };
        norm = data.normal || { x: 0, y: 0, z: -1 };
        rotDeg = data.rotation || 0;
        scl = data.scale || 1;
      } else {
        // Legacy fallback: assume positions[i] is a 2D coordinate; add default z and normal
        pos = { x: data.x || 0, y: data.y || 1, z: 0 };
        norm = { x: 0, y: 0, z: -1 };
        rotDeg = rotations[i] || 0;
        scl = scales[i] || 1;
      }
      // Create a thin decal (z-size of 0.1) scaled by the provided factor
      const decalSize = new BABYLON.Vector3(0.3, 0.3, 0.1).scale(scl);
      const decalMesh = BABYLON.MeshBuilder.CreateDecal(
        'tattooDecal',
        modelRef.current,
        {
          position: new BABYLON.Vector3(pos.x, pos.y, pos.z),
          normal: new BABYLON.Vector3(norm.x, norm.y, norm.z),
          size: decalSize,
          angle: (rotDeg * Math.PI) / 180,
        }
      );
      const decalMat = createDecalMaterial(url, sceneRef.current);
      decalMesh.material = decalMat;
      decalMeshesRef.current.push(decalMesh);
    });
  }, [images, placements, positions, rotations, scales]);

  // ---------------------------
  // Dynamic Texture Approach (Legacy)
  // ---------------------------
  const applyDynamicTextureApproach = useCallback(
    (mesh) => {
      if (!dynamicTextureRef.current || !sceneRef.current) return;
      const ctx = dynamicTextureRef.current.getContext();
      ctx.clearRect(0, 0, 2048, 2048);
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, 2048, 2048);
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      images.forEach((url, i) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = () => {
          const maxSize = 400;
          const aspect = image.width / image.height;
          let drawW = maxSize;
          let drawH = maxSize;
          if (aspect > 1) {
            drawH = drawW / aspect;
          } else {
            drawW = drawH * aspect;
          }
          // Expect positions[i] to be a 2D coordinate object: { x, y }
          const { x = 800, y = 1000 } = positions[i] || {};
          const rotDeg = rotations[i] || 0;
          const scl = scales[i] || 1;
          ctx.save();
          ctx.translate(x + drawW / 2, y + drawH / 2);
          ctx.rotate((rotDeg * Math.PI) / 180);
          ctx.scale(scl, scl);
          ctx.drawImage(image, -drawW / 2, -drawH / 2, drawW, drawH);
          ctx.restore();
          dynamicTextureRef.current.update();
        };
        image.src = url;
      });
      // Apply the dynamic texture to the mesh material
      const mat = mesh.material || new BABYLON.StandardMaterial('modelMat', sceneRef.current);
      mat.diffuseTexture = dynamicTextureRef.current;
      mat.backFaceCulling = false;
      mesh.material = mat;
    },
    [images, positions, rotations, scales]
  );

  // ======================
  //   Interaction Handlers
  // ======================
  const handleAvatarClick = () => {
    if (creator && creator.username) {
      navigate(`/userpage/${creator.username}`);
    }
  };

  const handleShareClick = useCallback((e) => {
    e.stopPropagation();
    setShareVisible((prev) => !prev);
  }, []);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(uniqueTattooUrl).then(() => {
      alert('Link copied to clipboard!');
    });
  }, [uniqueTattooUrl]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShareVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // ======================
  //   Like / Favorite Handlers
  // ======================
  const handleLikeClick = useCallback(
    debounce(async () => {
      if (!currentUserId) return;
      const endpoint = isLiked
        ? `/tattoo_designs/${tattoo.id}/unlike`
        : `/tattoo_designs/${tattoo.id}/like`;
      const originalLikeCount = likeCount;
      // Optimistic update
      setIsLiked(!isLiked);
      setLikeCount(isLiked ? originalLikeCount - 1 : originalLikeCount + 1);
      try {
        const response = await fetch(`https://koi-2028.onrender.com${endpoint}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: currentUserId }),
        });
        if (!response.ok) {
          console.error('Response:', await response.text());
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setLikeCount(data.likes || 0);
        onStateChange({
          isLiked: !isLiked,
          likeCount: data.likes,
          isFavorited,
          favoriteCount,
        });
      } catch (error) {
        console.error('Error updating like status:', error);
        setIsLiked(isLiked);
        setLikeCount(originalLikeCount);
      }
    }, 100),
    [isLiked, likeCount, currentUserId, tattoo.id, isFavorited, favoriteCount, onStateChange]
  );

  const handleFavoriteClick = useCallback(
    debounce(async () => {
      if (!currentUserId) return;
      const endpoint = isFavorited
        ? `/tattoo_designs/${tattoo.id}/unfavorite`
        : `/tattoo_designs/${tattoo.id}/favorite`;
      const originalFavoriteCount = favoriteCount;
      // Optimistic update
      setIsFavorited(!isFavorited);
      setFavoriteCount(isFavorited ? originalFavoriteCount - 1 : originalFavoriteCount + 1);
      try {
        const response = await fetch(`https://koi-2028.onrender.com${endpoint}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: currentUserId }),
        });
        if (!response.ok) {
          console.error('Response:', await response.text());
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setFavoriteCount(data.total_favorites || 0);
        onStateChange({
          isLiked,
          likeCount,
          isFavorited: !isFavorited,
          favoriteCount: data.total_favorites,
        });
      } catch (error) {
        console.error('Error updating favorite status:', error);
        setIsFavorited(isFavorited);
        setFavoriteCount(originalFavoriteCount);
      }
    }, 100),
    [isFavorited, favoriteCount, currentUserId, tattoo.id, isLiked, likeCount, onStateChange]
  );

  // ======================
  //   Auto-Scale & Center Helper
  // ======================
  function autoScaleMesh(mesh) {
    if (mesh.getChildren && mesh.getChildren().length > 0) {
      const merged = BABYLON.Mesh.MergeMeshes(
        mesh.getChildren(true),
        true,
        true,
        undefined,
        false,
        true
      );
      if (merged) {
        mesh = merged;
      }
    }
    mesh.computeWorldMatrix(true);
    const bbInfo = mesh.getBoundingInfo();
    const sizeVec = bbInfo.boundingBox.extendSizeWorld;
    const maxDim = Math.max(sizeVec.x, sizeVec.y, sizeVec.z);
    const desiredSize = 2;
    const scaleFactor = desiredSize / maxDim;
    mesh.scaling.setAll(scaleFactor);
  
    // Apply the rotation first
    mesh.rotation.y += Math.PI / 2;
    mesh.computeWorldMatrix(true);
  
    // Then recenter
    const bb2 = mesh.getBoundingInfo();
    const center = bb2.boundingBox.centerWorld.clone();
    mesh.position.subtractInPlace(center);
    mesh.computeWorldMatrix(true);
  
    return mesh;
  }

  // ======================
  //   Babylon Scene Initialization
  // ======================
  useEffect(() => {
    if (!isOpen || !canvasRef.current) return;

    let localScene = null;

    const initBabylonScene = async () => {
      if (!engineRef.current) {
        engineRef.current = new BABYLON.Engine(canvasRef.current, true);
      }
      // Dispose previous scene if exists
      if (sceneRef.current) {
        sceneRef.current.dispose();
      }
      sceneRef.current = new BABYLON.Scene(engineRef.current);
      localScene = sceneRef.current;

      // Create a consistent ArcRotateCamera
      const camera = new BABYLON.ArcRotateCamera(
        'Camera',
        0,    // alpha
        1.1,  // beta
        6,    // radius
        BABYLON.Vector3.Zero(),
        localScene
      );
      camera.attachControl(canvasRef.current, true);

      // Add hemispheric light
      new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 1, 0), localScene);
      localScene.clearColor = new BABYLON.Color4(0.1255, 0.1333, 0.1451, 1.0);

      const preventScroll = (event) => {
        if (canvasRef.current && event.target === canvasRef.current) {
          event.preventDefault();
        }
      };
      document.addEventListener('wheel', preventScroll, { passive: false });

      // For dynamic texture approach, create a dynamic texture
      if (!useDecals) {
        dynamicTextureRef.current = new BABYLON.DynamicTexture(
          'dynamicTexture',
          { width: 2048, height: 2048 },
          localScene
        );
        dynamicTextureRef.current.hasAlpha = true;
      }

      setIsLoading(true);

      // Load the model
      BABYLON.SceneLoader.ImportMesh(
        '',
        'assets/',
        modelUrl,
        localScene,
        (meshes) => {
          if (!meshes || meshes.length === 0) {
            console.error('No meshes loaded in ModelPreviewModal!');
            setIsLoading(false);
            return;
          }
          // Auto-scale and center the model
          modelRef.current = autoScaleMesh(meshes[0]);
          if (useDecals) {
            applyDecalApproach();
          } else {
            applyDynamicTextureApproach(modelRef.current);
          }

          // Slight delay before finalizing decal application
          setTimeout(() => {
            if (useDecals) {
              applyDecalApproach();
            } else {
              applyDynamicTextureApproach(modelRef.current);
            }
            setIsLoading(false);
          }, 100);
        }
      );

      engineRef.current.runRenderLoop(() => {
        localScene.render();
      });

      const handleResize = () => {
        if (engineRef.current) {
          engineRef.current.resize();
        }
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    };

    initBabylonScene();

    // Cleanup when modal closes or component unmounts
    return () => {
      if (localScene) {
        localScene.dispose();
      }
      if (engineRef.current) {
        engineRef.current.stopRenderLoop();
      }
    };
  }, [
    isOpen,
    modelUrl,
    images,
    positions,
    rotations,
    scales,
    placements,
    useDecals,
    applyDecalApproach,
    applyDynamicTextureApproach,
  ]);

  if (!isOpen) return null;

  // ======================
  //   Render Modal UI
  // ======================
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10">
          <div className="spinner"></div>
        </div>
      )}

      <div className="bg-white rounded-lg overflow-hidden relative w-[80vw] h-[80vh] max-w-4xl flex">
        {/* Close Button */}
        <button
          className="absolute top-3 right-3 close-button z-10 text-white bg-gray-800 p-1 rounded"
          onClick={onClose}
        >
          X
        </button>

        {/* Canvas for 3D rendering */}
        <div className="flex-grow relative">
          <canvas ref={canvasRef} className="w-full h-full" />
        </div>

        {/* Right-side UI icons: Avatar, Like, Favorite, Share */}
        <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex flex-col items-center space-y-4 z-20">
          <div className="rounded-full p-1 mb-2 hover:cursor-pointer" onClick={handleAvatarClick}>
            {creator && creator.user_avatar ? (
              <img
                src={creator.user_avatar}
                alt={creator.username || 'User'}
                className="w-12 h-12 rounded-full"
              />
            ) : (
              <FaUserCircle size={32} className="cursor-pointer text-white" />
            )}
          </div>
          <Interaction
            icon={
              <FaHeart
                className={`text-2xl cursor-pointer hover:text-rose-500 ${isLiked ? 'text-rose-500' : 'text-white'}`}
              />
            }
            displayNumber={likeCount}
            onClick={handleLikeClick}
          />
          <Interaction
            icon={
              <FaBookmark
                className={`text-2xl cursor-pointer hover:text-rose-500 ${isFavorited ? 'text-rose-500' : 'text-white'}`}
              />
            }
            displayNumber={favoriteCount}
            onClick={handleFavoriteClick}
          />
          <div className="relative" ref={shareRef}>
            <Interaction icon={<FaShare className="text-2xl text-white" />} onClick={handleShareClick} />
            {shareVisible && (
              <div className="absolute top-full right-0 mt-2 bg-white shadow-md p-2 rounded-md flex flex-row gap-2">
                <FacebookShareButton url={uniqueTattooUrl} quote="Check out this tattoo!">
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={uniqueTattooUrl} title="Check out this tattoo!">
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={uniqueTattooUrl} title="Check out this tattoo!">
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <button onClick={handleCopyLink} className="flex items-center justify-center text-gray-500 hover:text-rose-500">
                  <FaLink size={24} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Spinner Styling */}
      <style>{`
        .spinner {
          width: 50px;
          height: 50px;
          border: 6px solid rgba(255, 255, 255, 0.3);
          border-top-color: #fff;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

// --- Interaction Component for UI Icons ---
const Interaction = ({ icon, displayNumber, onClick }) => (
  <div onClick={onClick} className="group flex flex-col items-center hover:cursor-pointer">
    <div className="rounded-full mb-1">{icon}</div>
    {displayNumber !== undefined && <span className="text-white text-sm">{displayNumber}</span>}
  </div>
);

export default ModelPreviewModal;
