import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TattooPreview from './TattooPreview';
import ModelPreviewModal from './ModelPreviewModal';
import { AuthContext } from './AuthContext';

const Home = () => {
  const [state, setState] = useState({
    designs: [],
    isLoading: true,
  });
  // New state to track how many previews to display
  const [displayedCount, setDisplayedCount] = useState(20);
  const loadMoreRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTattoo, setSelectedTattoo] = useState(null);
  const initialModalOpened = useRef(false);

  const { user } = useContext(AuthContext);
  const { tattooId } = useParams();

  // Function to fetch and display full tattoo details in the modal
  const handleOpenModal = useCallback(async (designId) => {
    try {
      const response = await axios.get(`https://koi-2028.onrender.com/tattoo_designs/${designId}`);
      setSelectedTattoo(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching tattoo details:', error);
    }
  }, []);

  // Fetch tattoo design previews from the API
  const fetchPreviews = useCallback(async () => {
    try {
      const response = await axios.get('https://koi-2028.onrender.com/tattoo_designs/previews');
      setState({ designs: response.data, isLoading: false });
    } catch (error) {
      console.error('Error fetching tattoo previews:', error);
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  }, []);

  // Fetch previews when the component mounts
  useEffect(() => {
    fetchPreviews();
  }, [fetchPreviews]);

  // Automatically open the modal if a tattooId is present in the URL
  useEffect(() => {
    if (!state.isLoading && tattooId && state.designs.length > 0 && !initialModalOpened.current) {
      handleOpenModal(tattooId);
      initialModalOpened.current = true;
    }
  }, [tattooId, state.designs, state.isLoading, handleOpenModal]);

  // Set up an IntersectionObserver to implement infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && displayedCount < state.designs.length) {
          setDisplayedCount((prev) => prev + 20);
        }
      },
      { rootMargin: '100px' }
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [state.designs, displayedCount]);

  // Close modal and clear selected tattoo
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTattoo(null);
  };

  return (
    <main className="px-4 md:pl-20 md:pr-4 py-8 min-h-screen bg-gray-800">
      <h1 className="text-3xl font-bold text-center text-white mb-8">
        Popular Tattoo Designs:
      </h1>
      <div className="bg-gray-800">
        {state.isLoading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {Array.from({ length: 12 }).map((_, index) => (
              <div
                key={index}
                className="bg-gray-700 animate-pulse rounded-md"
                style={{ aspectRatio: '800 / 600', width: '100%' }}
              ></div>
            ))}
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {state.designs.slice(0, displayedCount).map((design) => (
                <TattooPreview
                  key={design.id}
                  design={design}
                  onClick={() => handleOpenModal(design.id)}
                />
              ))}
            </div>
            {/* Invisible div to trigger loading more previews */}
            <div ref={loadMoreRef} style={{ height: '1px' }}></div>
          </>
        )}
      </div>
      {isModalOpen && selectedTattoo && (
        <ModelPreviewModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modelUrl={selectedTattoo.obj_file}
          images={selectedTattoo.images}
          positions={selectedTattoo.positions}
          rotations={selectedTattoo.rotations}
          placements={selectedTattoo.placements}
          useDecals={selectedTattoo.use_decals}
          scales={selectedTattoo.scales}
          tattoo={selectedTattoo}
          onStateChange={() => {}}
        />
      )}
    </main>
  );
};

export default Home;
