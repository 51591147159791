import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import { useLocation, useNavigate } from 'react-router-dom';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import '@babylonjs/loaders/OBJ/objFileLoader';
import ProModelUpgradePrompt from './ProModelUpgradePrompt';
import TopNavigation from './TopNavigation';
import PreviewImages from './PreviewImages';
import { FaCloudUploadAlt, FaShare, FaCamera, FaLink } from "react-icons/fa";
import { MdSaveAlt } from "react-icons/md";
import { BsGearFill } from "react-icons/bs"; 
import { AuthContext } from './AuthContext';
import axios from 'axios';
import UpgradePrompt from './UpgradePrompt';
import Signup from './Signup';

// -------------------------------------------------
// Helper Functions for Mobile Detection & Image Optimization
// -------------------------------------------------

// Returns true if the current device is mobile.
function isMobile() {
  return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// Downscale an image using an offscreen canvas. By default, it limits the image to 512×512.
function getOptimizedImage(imageUrl, maxWidth = 512, maxHeight = 512) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const scale = Math.min(maxWidth / img.width, maxHeight / img.height, 1);
      const canvas = document.createElement('canvas');
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;
      const ctx = canvas.getContext('2d');
      
      // Clear the canvas and ensure transparency is preserved
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Save the current context state
      ctx.save();
      
      // Set global composite operation to ensure transparency is respected
      ctx.globalCompositeOperation = 'source-over';
      
      // Draw the image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      
      // Restore the context state
      ctx.restore();
      
      // Use PNG format to preserve transparency
      resolve(canvas.toDataURL('image/png', 1.0));
    };
    img.onerror = reject;
    img.src = imageUrl;
  });
}

// -------------------------------------------------
// Helper: Create an image from text using an offscreen canvas.
// -------------------------------------------------
function createTextImage(text, font = 'Arial', fontSize = 40, color = '#000000') {
  // Create a temporary canvas.
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  
  // Set the font so we can measure the text.
  context.font = `${fontSize}px ${font}`;
  const metrics = context.measureText(text);
  
  // Set canvas dimensions based on measured text plus some padding.
  canvas.width = metrics.width + 20;
  canvas.height = fontSize * 1.5;
  
  // Reset the font since canvas dimensions reset the context.
  context.font = `${fontSize}px ${font}`;
  context.textBaseline = 'middle';
  context.fillStyle = color;
  
  // Draw the text on the canvas.
  context.fillText(text, 10, canvas.height / 2);
  
  // Return the image as a data URL.
  return canvas.toDataURL('image/png');
}

// -------------------------------------------------
// Utility: Auto-scale and recenter a model mesh
// -------------------------------------------------
function autoScaleMesh(mesh) {
  if (mesh.getChildren && mesh.getChildren().length > 0) {
    const merged = BABYLON.Mesh.MergeMeshes(
      mesh.getChildren(true),
      true,
      true,
      undefined,
      false,
      true
    );
    if (merged) {
      mesh = merged;
    }
  }
  mesh.computeWorldMatrix(true);
  const bbInfo = mesh.getBoundingInfo();
  const sizeVec = bbInfo.boundingBox.extendSizeWorld;
  const maxDim = Math.max(sizeVec.x, sizeVec.y, sizeVec.z);

  const desiredSize = 2;
  const scaleFactor = desiredSize / maxDim;
  mesh.scaling.setAll(scaleFactor);
  mesh.rotation.y += Math.PI / 2;
  mesh.computeWorldMatrix(true);
  const bb2 = mesh.getBoundingInfo();
  const center = bb2.boundingBox.centerWorld.clone();
  mesh.position.subtractInPlace(center);
  mesh.computeWorldMatrix(true);

  return mesh;
}

// -------------------------------------------------
// UI Components for icons
// -------------------------------------------------
const CanvasIcon = ({ icon, text, onClick }) => (
  <div className="flex flex-col items-center relative cursor-pointer" onClick={onClick}>
    <div className="canvas-icons">
      {icon}
      {text && <span className="canvas-tooltip ">{text}</span>}
    </div>
  </div>
);

const SettingsIcon = ({ icon, text, onClick }) => (
  <div className="settings-icon text-2xl cursor-pointer" onClick={onClick}>
    {icon}
    {text && <span className="canvas-tooltip">{text}</span>}
  </div>
);

// -------------------------------------------------
// Main Component: CreateCanvas
// -------------------------------------------------
function CreateCanvas() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  // If navigating here with a "tattoo" object from the previous route
  const { tattoo } = state || {};

  const [tattooTextures, setTattooTextures] = useState(tattoo?.images || []);
  const [tattooPlacements, setTattooPlacements] = useState(
    Array.isArray(tattoo?.placements) ? tattoo.placements : []
  );
  // The index of the currently selected decal (if any)
  const [selectedDecalIndex, setSelectedDecalIndex] = useState(null);

  // ------------------------------
  // New State for Text Input Modal
  // ------------------------------
  const [showTextModal, setShowTextModal] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [selectedFont, setSelectedFont] = useState('Arial');
  const [fontSize, setFontSize] = useState(40);
  const [textColor, setTextColor] = useState('#000000');

  // ------------------------------
  // Refs for Babylon & other scene objects
  // ------------------------------
  const canvasRef = useRef(null);
  const engineRef = useRef(null);
  const sceneRef = useRef(null);
  const humanoidRef = useRef(null);
  // Store the decal meshes
  const decalMeshesRef = useRef([]);
  const highlightLayerRef = useRef(null);
  const [selectedModel, setSelectedModel] = useState(tattoo?.obj_file || 'MaleTorso.obj');
  const [isBabylonInitialized, setIsBabylonInitialized] = useState(false);
  // For dragging decal meshes
  const draggingDecalIndexRef = useRef(-1);
  // Reference for the camera (to attach/detach control)
  const cameraRef = useRef(null);

  // ------------------------------
  // UI State
  // ------------------------------
  const fileInputRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
  const [showProModelUpgradePrompt, setShowProModelUpgradePrompt] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [shareUrl, setShareUrl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [savedImageUrl, setSavedImageUrl] = useState(null);

  // ------------------------------
  // Refs to track if alert has already been shown
  // ------------------------------
  const rotationAlertShownRef = useRef(false);
  const scaleAlertShownRef = useRef(false);

  // ------------------------------
  // File Upload & Auto-Decal Creation
  // ------------------------------
  const handleFileUpload = useCallback((e) => {
    if (!user) {
      setShowSignupModal(true);
      return;
    }
    const canCreateDesign = user.is_premium || (user.free_designs_created < 1);
  
    if (!canCreateDesign) {
      setShowUpgradePrompt(true);
      return;
    }

    const files = e.target.files;
    if (files && files.length > 0) {
      const newURLs = Array.from(files).map(file => URL.createObjectURL(file));

      setTattooTextures(prevTextures => [...prevTextures, ...newURLs]);

      // For each new image, create a "placement" in front of the model.
      // A small vertical offset (+0.2) is added.
      setTattooPlacements(prevPlacements => {
        const updated = [...prevPlacements];
        newURLs.forEach(() => {
          updated.push(createCenterPlacement());
        });
        return updated;
      });

      // Auto-select the last one.
      setSelectedDecalIndex(tattooTextures.length + files.length - 1);

      // Clear the file input so the same file can be uploaded again.
      e.target.value = "";
    }
  }, [user, tattooTextures]);

  const tattooPlacementsRef = useRef(tattooPlacements);
  useEffect(() => {
    tattooPlacementsRef.current = tattooPlacements;
  }, [tattooPlacements]);

  // Adjust the starting position upward by 0.2 on the Y-axis.
  // For the arm model ("Arm_Right_Vertical.obj"), we do not add the extend.x value.
  function createCenterPlacement() {
    if (humanoidRef.current) {
      humanoidRef.current.computeWorldMatrix(true);
      const bounding = humanoidRef.current.getBoundingInfo();
      const center = bounding.boundingBox.centerWorld.clone();
      const extend = bounding.boundingBox.extendSizeWorld;
      // For arm model, remove extend.x; otherwise use extend.x.
      const xPos = selectedModel === "Arm_Right_Vertical.obj" ? center.x : center.x + extend.x - 0.1;
      return {
        position: { x: xPos, y: center.y + 0.2, z: center.z },
        normal: { x: 0, y: 0, z: 0 },
        rotation: 0,
        scale: 4
      };
    }
    return {
      position: { x: 2, y: 0.2, z: 0 },
      normal: { x: -1, y: 0, z: 0 },
      rotation: 0,
      scale: 3
    };
  }

  // ------------------------------
  // Initialize Babylon Scene
  // ------------------------------
  const initializeBabylon = useCallback(async () => {
    if (!canvasRef.current) return;

    // Dispose old engine if it exists.
    if (engineRef.current) {
      engineRef.current.dispose();
    }

    engineRef.current = new BABYLON.Engine(canvasRef.current, true);
    engineRef.current.setHardwareScalingLevel(1 / (window.devicePixelRatio || 1));
    engineRef.current.resize();

    const scene = new BABYLON.Scene(engineRef.current);
    sceneRef.current = scene;

    // Camera setup.
    const camera = new BABYLON.ArcRotateCamera("Camera", 0, 1.1, 6, BABYLON.Vector3.Zero(), scene);
    camera.attachControl(canvasRef.current, true);
    cameraRef.current = camera;

    new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);
    scene.clearColor = new BABYLON.Color4(0.1255, 0.1333, 0.1451, 1.0);

    // Load the selected model.
    let mainMesh = await loadModel(scene, selectedModel);
    humanoidRef.current = autoScaleMesh(mainMesh);

    const preventScroll = (event) => {
      if (canvasRef.current && event.target === canvasRef.current) {
        event.preventDefault();
      }
    };
    document.addEventListener("wheel", preventScroll, { passive: false });

    // Create a highlight layer.
    highlightLayerRef.current = new BABYLON.HighlightLayer("hl1", scene);

    // Pointer events for dragging decals.
    scene.onPointerDown = (evt, pickResult) => {
      if (!pickResult.hit) {
        setSelectedDecalIndex(null);
        return;
      }
      const clickedMesh = pickResult.pickedMesh;
      // Check if the clicked mesh is a decal, its parent, or its hitbox.
      const decalIndex = decalMeshesRef.current.findIndex(decal => {
        return decal === clickedMesh ||
          (clickedMesh.parent && clickedMesh.parent === decal) ||
          (decal.metadata && decal.metadata.hitbox && clickedMesh === decal.metadata.hitbox);
      });
      if (decalIndex !== -1) {
        draggingDecalIndexRef.current = decalIndex;
        setSelectedDecalIndex(decalIndex);
        cameraRef.current.detachControl(canvasRef.current);
      } else {
        setSelectedDecalIndex(null);
      }
    };

    scene.onPointerUp = () => {
      if (draggingDecalIndexRef.current >= 0) {
        cameraRef.current.attachControl(canvasRef.current, true);
      }
      draggingDecalIndexRef.current = -1;
    };

    scene.onPointerMove = () => {
      const idx = draggingDecalIndexRef.current;
      if (idx < 0) return;
      const pick = scene.pick(scene.pointerX, scene.pointerY, mesh => mesh === humanoidRef.current);
      if (pick && pick.hit) {
        setTattooPlacements(prev => {
          const arr = [...prev];
          if (!arr[idx]) return arr;
          const old = arr[idx];
          const p = pick.pickedPoint.clone();
          const n = pick.getNormal(true);
          arr[idx] = {
            ...old,
            position: { x: p.x, y: p.y, z: p.z },
            normal: { x: n.x, y: n.y, z: n.z }
          };
          return arr;
        });
      }
    };

    engineRef.current.runRenderLoop(() => {
      scene.render();
    });

    const handleResize = () => engineRef.current.resize();
    window.addEventListener("resize", handleResize);

    setIsBabylonInitialized(true);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (engineRef.current) {
        engineRef.current.stopRenderLoop();
        engineRef.current.dispose();
      }
    };
  }, [selectedModel]);

  async function loadModel(scene, modelName) {
    return new Promise((resolve, reject) => {
      BABYLON.SceneLoader.ImportMesh(
        "",
        "assets/",
        modelName,
        scene,
        meshes => {
          if (meshes && meshes.length > 0) {
            resolve(meshes[0]);
          } else {
            reject("No mesh loaded");
          }
        }
      );
    });
  }

  useEffect(() => {
    initializeBabylon();
  }, [initializeBabylon]);

  // ------------------------------
  // Re-apply decals when placements or textures change
  // ------------------------------
  useEffect(() => {
    if (isBabylonInitialized && humanoidRef.current) {
      applyDecalsToModel();
    }
  }, [tattooPlacements, tattooTextures, isBabylonInitialized, selectedDecalIndex]);

  // ------------------------------
  // Apply Decals to the Model
  // ------------------------------
  const createDecalMaterial = async (textureURL, scene, isMobileDevice) => {
    const decalMat = new BABYLON.StandardMaterial("decalMat", scene);
    
    try {
      let finalTextureURL = textureURL;
      
      // Optimize for mobile if needed
      if (isMobileDevice) {
        finalTextureURL = await getOptimizedImage(textureURL);
      }
      
      // Create texture with proper settings
      const texture = new BABYLON.Texture(finalTextureURL, scene);
      texture.hasAlpha = true;
      texture.uScale = 1;
      texture.vScale = 1;
      
      // Apply texture to material with proper alpha settings
      decalMat.diffuseTexture = texture;
      decalMat.useAlphaFromDiffuseTexture = true;
      decalMat.needDepthPrePass = true;
      decalMat.separateCullingPass = true;
      decalMat.backFaceCulling = false;
      decalMat.transparencyMode = BABYLON.Material.MATERIAL_ALPHABLEND;
      decalMat.alphaMode = BABYLON.Engine.ALPHA_COMBINE;
      decalMat.zOffset = -1;
      
      return decalMat;
    } catch (err) {
      console.error("Error creating decal material:", err);
      throw err;
    }
  };

  const applyDecalsToModel = useCallback(async () => {
    if (!sceneRef.current || !humanoidRef.current) return;

    // Clear old decals
    decalMeshesRef.current.forEach(d => d.dispose());
    decalMeshesRef.current = [];

    // Remove highlights
    highlightLayerRef.current?.removeAllMeshes();

    // Create all decals
    for (let index = 0; index < tattooTextures.length; index++) {
      const textureURL = tattooTextures[index];
      const data = tattooPlacements[index];
      if (!data) continue;

      const { position, normal, rotation, scale } = data;

      // Create decal with thin depth
      const decalSize = new BABYLON.Vector3(0.3, 0.3, 0.1).scale(scale);
      const decalMesh = BABYLON.MeshBuilder.CreateDecal(
        "tattooDecal",
        humanoidRef.current,
        {
          position: new BABYLON.Vector3(position.x, position.y, position.z),
          normal: new BABYLON.Vector3(normal.x, normal.y, normal.z),
          size: decalSize,
          angle: (rotation * Math.PI) / 180,
        }
      );

      try {
        // Create material with proper texture handling
        const decalMat = await createDecalMaterial(
          textureURL, 
          sceneRef.current, 
          isMobile()
        );
        decalMesh.material = decalMat;

        // Setup hitbox and interaction
        const hitboxSize = 0.3 * scale * 3;
        const hitbox = BABYLON.MeshBuilder.CreatePlane(
          "decalHitbox",
          { size: hitboxSize },
          sceneRef.current
        );
        hitbox.parent = decalMesh;
        hitbox.position = BABYLON.Vector3.Zero();
        hitbox.isVisible = false;
        hitbox.isPickable = true;
        hitbox.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
        decalMesh.metadata = { hitbox };

        // Setup action manager for cursor changes
        decalMesh.actionManager = new BABYLON.ActionManager(sceneRef.current);
        decalMesh.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPointerOverTrigger,
            () => {
              if (canvasRef.current) {
                canvasRef.current.style.cursor = 'grab';
              }
            }
          )
        );
        decalMesh.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPointerOutTrigger,
            () => {
              if (canvasRef.current) {
                canvasRef.current.style.cursor = 'default';
              }
            }
          )
        );

        decalMeshesRef.current.push(decalMesh);

        // Highlight selected decal
        if (index === selectedDecalIndex) {
          highlightLayerRef.current?.addMesh(decalMesh, BABYLON.Color3.Red());
        }
      } catch (err) {
        console.error("Error applying decal:", err);
        decalMesh.dispose();
      }
    }
  }, [tattooPlacements, tattooTextures, selectedDecalIndex]);

  // ------------------------------
  // Rotation & Scale UI Controls
  // ------------------------------
  const handleRotationChange = (index, newVal) => {
    setTattooPlacements(prev => {
      const arr = [...prev];
      const old = arr[index];
      arr[index] = { ...old, rotation: newVal };
      return arr;
    });
  };

  const handleScaleChange = (index, newVal) => {
    setTattooPlacements(prev => {
      const arr = [...prev];
      const old = arr[index];
      arr[index] = { ...old, scale: newVal };
      return arr;
    });
  };

  // ------------------------------
  // Model Dropdown Controls
  // ------------------------------
  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen(prev => !prev);
  }, []);

  const handleModelChange = useCallback((model) => {
    // Check for restricted models.
    const isRestricted = (model === 'femaleFB4.obj' || model === 'MFullBody2.obj');
    if (!user?.is_premium && isRestricted) {
      setShowProModelUpgradePrompt(true);
      return;
    }

    setSelectedModel(model);
    setIsDropdownOpen(false);

    // Clear existing decals & placements.
    setTattooTextures([]);
    setTattooPlacements([]);
    setSelectedDecalIndex(null);

    // Reset the file input so new uploads work.
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    // Dispose of the old mesh.
    if (humanoidRef.current) {
      humanoidRef.current.dispose(false, true);
      humanoidRef.current = null;
    }
  }, [user]);

  // ------------------------------
  // Share, Save & Screenshot Controls
  // ------------------------------
  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert('Link copied to clipboard!');
    });
  };

  const shareTattoo = useCallback(() => {
    setShowConfirmModal(true);
  }, []);

  // Confirm Save modal handler.
  const handleConfirmSave = useCallback(async () => {
    setShowConfirmModal(false);
    const newTattoo = await saveTattooDesign({ isForSharing: true });
    if (!newTattoo) return;
    const tattooId = newTattoo.id;
    const url = `${window.location.origin}/#/userpage/${user?.username}/${tattooId}`;
    setShareUrl(url);
  }, [user, tattooPlacements, tattooTextures, selectedModel]);

  // Save function: if no designs exist, it alerts the user.
  const saveTattooDesign = async (options = { isForSharing: false }) => {
    const { isForSharing } = options;
    if (!user) {
      alert("You need to be logged in to save a tattoo design.");
      return null;
    }
    // Check if any designs have been added.
    if (tattooTextures.length === 0) {
      alert("Please add at least one design before saving.");
      return null;
    }

    setIsSaving(true);

    // Remove any UI overlays before capture.
    highlightLayerRef.current?.removeAllMeshes();
    sceneRef.current.render();

    try {
      const dpr = window.devicePixelRatio || 1;
      const targetWidth = 800;
      const targetHeight = 600;
      const isMobileDevice = isMobile();
      const scaleFactor = isMobileDevice ? 1 : 5;

      const previewImageBlob = await captureHighResolutionImage(
        targetWidth,
        targetHeight,
        scaleFactor,
        dpr
      );

      const formData = new FormData();
      formData.append("user_id", user.id);

      const placementsData = tattooPlacementsRef.current.map(
        ({ position, normal, rotation, scale }) => ({ position, normal, rotation, scale })
      );
      console.log("Sending placements:", placementsData);
      formData.append("placements", JSON.stringify(placementsData));
      formData.append("obj_file", selectedModel);
      formData.append("preview_image", previewImageBlob, "preview_image.png");

      for (let i = 0; i < tattooTextures.length; i++) {
        const blobUrl = tattooTextures[i];
        const response = await fetch(blobUrl);
        const tattooBlob = await response.blob();
        formData.append("images", tattooBlob, `image_${i}.png`);
      }

      const res = await axios.post(
        "https://koi-2028.onrender.com/tattoo_designs",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (res.status === 201) {
        alert("Tattoo design saved successfully!");
        if (!isForSharing) {
          navigate(`/userpage/${user.username}`);
        }
        return res.data;
      }
    } catch (err) {
      if (err.response?.status === 403) {
        setShowUpgradePrompt(true);
      } else {
        console.error("Error saving tattoo design:", err);
        alert("An error occurred while saving. Please try again.");
      }
    } finally {
      setIsSaving(false);
    }

    return null;
  };

  const saveCanvasAsImage = useCallback(async () => {
    await saveTattooDesign();
    return new Promise(resolve => {
      if (!canvasRef.current || !engineRef.current) return;

      engineRef.current.stopRenderLoop();
      sceneRef.current.render();

      canvasRef.current.toBlob(blob => {
        if (!blob) return;
        const imageUrl = URL.createObjectURL(blob);
        setSavedImageUrl(imageUrl);

        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = "tattoo-design.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        resolve(imageUrl);
      }, "image/png");

      engineRef.current.runRenderLoop(() => {
        sceneRef.current.render();
      });
    });
  }, [tattooPlacements, tattooTextures, selectedModel]);

  const captureHighResolutionImage = useCallback(
    (targetWidth = 800, targetHeight = 600, dpr = window.devicePixelRatio || 1) => {
      return new Promise(resolve => {
        if (!canvasRef.current || !engineRef.current) return;
        
        engineRef.current.stopRenderLoop();
        sceneRef.current.render();
  
        const finalWidth = targetWidth * dpr;
        const finalHeight = targetHeight * dpr;
  
        const offscreenCanvas = document.createElement('canvas');
        offscreenCanvas.width = finalWidth;
        offscreenCanvas.height = finalHeight;
        const offscreenContext = offscreenCanvas.getContext('2d');
  
        const bgColor = '#202224';
        offscreenContext.fillStyle = bgColor;
        offscreenContext.fillRect(0, 0, finalWidth, finalHeight);
  
        const sourceWidth = engineRef.current.getRenderWidth();
        const sourceHeight = engineRef.current.getRenderHeight();
  
        const scale = finalHeight / sourceHeight;
        const drawWidth = sourceWidth * scale;
        const drawHeight = finalHeight;
  
        const dx = (finalWidth - drawWidth) / 2;
        const dy = 0;
  
        offscreenContext.drawImage(
          canvasRef.current,
          0,
          0,
          sourceWidth,
          sourceHeight,
          dx,
          dy,
          drawWidth,
          drawHeight
        );
  
        offscreenCanvas.toBlob(blob => {
          resolve(blob);
        }, 'image/png', 1.0);
  
        engineRef.current.runRenderLoop(() => {
          sceneRef.current.render();
        });
      });
    },
    []
  );

  // ------------------------------
  // Upgrade & Signup Controls
  // ------------------------------
  const handleClosePrompt = () => {
    setShowUpgradePrompt(false);
  };
  const handleUpgrade = () => {
    alert("Redirecting to the upgrade page...");
  };

  // ------------------------------
  // Determine which decal to adjust.
  // If no decal is selected but at least one exists, default to the first.
  // ------------------------------
  const activeDecalIndex = tattooPlacements.length > 0 ? (selectedDecalIndex !== null ? selectedDecalIndex : 0) : null;

  // ------------------------------
  // Render Layout
  // ------------------------------
  return (
    <div className="relative min-h-screen md:h-screen md:ml-16 mt-16 md:mt-0 bg-gray-800">
      {/* Main container: stacked on mobile and side-by-side on desktop */}
      <div className="flex flex-col md:flex-row h-full">
        {/* Left Panel */}
        <div className="md:w-1/3 p-4 border-r border-gray-300 bg-gray-800">
          {/* Upload Box */}
          <div className="border rounded-lg border-black text-center mb-4 bg-slate-100">
          <label 
            className="cursor-pointer block"
            onClick={(e) => {
              if (!user) {
                e.preventDefault();
                setShowSignupModal(true);
                return;
              }
              // Compute if the user can create a design.
              const canCreateDesign = user.is_premium || (user.free_designs_created < 5);
              if (!canCreateDesign) {
                e.preventDefault();
                setShowUpgradePrompt(true);
                return;
              }
              // Only trigger file input if allowed.
              fileInputRef.current.click();
            }}
          >
              <div className="p-5 flex flex-col items-center">
                <FaCloudUploadAlt size={70} className="upload-icon mt-2" />
                <p className="mt-2">
                  Drag and drop or Click here <br /> to upload an image
                </p>
                <span className="block text-sm mt-2 text-slate-600">
                  Upload any image from desktop
                </span>
              </div>
            </label>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileUpload}
              multiple
              hidden
            />
          </div>

          {/* Add Text Button */}
          <div className="mb-4">
            <button
              className="bg-rose-500 hover:bg-rose-600 text-white px-4 py-2 rounded w-full"
              onClick={() => {
                if (!user) {
                  setShowSignupModal(true);
                  return;
                }
                const canCreateDesign = user.is_premium || (user.free_designs_created < 5);
    
                if (!canCreateDesign) {
                  setShowUpgradePrompt(true);
                  return;
                }

                setShowTextModal(true);
              }}
            >
              Add Text
            </button>
          </div>

          {/* Layers Panel */}
          <div className="bg-slate-100 p-2 mb-4 max-h-60 overflow-y-auto">
            <h2 className="underline mb-2">Layers</h2>
            <PreviewImages
              images={tattooTextures}
              onDelete={(indexToRemove) => {
                setTattooTextures(prev => prev.filter((_, i) => i !== indexToRemove));
                setTattooPlacements(prev => prev.filter((_, i) => i !== indexToRemove));
                if (indexToRemove === selectedDecalIndex) {
                  setSelectedDecalIndex(null);
                }
              }}
              onSelect={(indexSelected) => {
                setSelectedDecalIndex(indexSelected);
              }}
              selectedImageIndex={selectedDecalIndex}
            />
          </div>

          {/* Adjustments Panel for Decal - Always Visible & Square */}
          <div
            className="bg-slate-100 p-2 mt-2 rounded"
            style={{ width: '100%', height: '25%', aspectRatio: '2/1' }}
          >
            <h3 className="underline mb-2">Adjust Tattoo</h3>
            {activeDecalIndex === null ? (
              <p>No designs available to adjust.</p>
            ) : (
              <>
                <div className="mb-2">
                  <label className="block text-sm mb-1">Rotation (°):</label>
                  <input
                    type="range"
                    min="0"
                    max="360"
                    value={tattooPlacements[activeDecalIndex].rotation}
                    onChange={(e) => {
                      if (!rotationAlertShownRef.current) {
                        rotationAlertShownRef.current = true;
                      }
                      handleRotationChange(activeDecalIndex, parseInt(e.target.value));
                    }}
                    className="w-full"
                  />
                </div>
                <div>
                  <label className="block text-sm mb-1">Scale:</label>
                  <input
                    type="range"
                    min="0.1"
                    max="10"
                    step="0.1"
                    value={tattooPlacements[activeDecalIndex].scale}
                    onChange={(e) => {
                      if (!scaleAlertShownRef.current) {
                        scaleAlertShownRef.current = true;
                      }
                      handleScaleChange(activeDecalIndex, parseFloat(e.target.value));
                    }}
                    className="w-full"
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Canvas & Controls Panel */}
        <div className="md:w-2/3 p-4 relative flex flex-col">
          {/* Canvas Container */}
          <div className="flex-grow relative">
            {/* For mobile, make the canvas nearly full-screen */}
            <canvas
              ref={canvasRef}
              id="renderCanvas"
              className="rounded-md w-full h-[70vh] md:h-full"
              style={{ backgroundColor: 'transparent', touchAction: 'none' }}
              onClick={() => setIsDropdownOpen(false)}
            ></canvas>

            {!isBabylonInitialized && (
              <div className="absolute inset-0 flex justify-center items-center bg-gray-800 bg-opacity-75 z-50">
                <div className="spinner"></div>
              </div>
            )}

            {/* Settings Dropdown (Gear Icon) */}
            <div className="absolute top-2 right-2">
              <SettingsIcon icon={<BsGearFill />} onClick={toggleDropdown} />
              {isDropdownOpen && (
                <div className="absolute right-0 mt-1 top-full w-48 bg-white text-gray-800 border border-gray-300 rounded-lg shadow-lg z-10">
                  <ul className="py-2">
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100 flex items-center"
                      onClick={() => handleModelChange('femaleFB4.obj')}
                    >
                      Female Full Body
                      <span className={user?.is_premium ? 'text-blue-300 ml-2' : 'text-gray-400 ml-2'}>♦</span>
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100 flex items-center"
                      onClick={() => handleModelChange('MFullBody2.obj')}
                    >
                      Male Full Body
                      <span className={user?.is_premium ? 'text-blue-300 ml-2' : 'text-gray-400 ml-2'}>♦</span>
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('MaleTorso.obj')}
                    >
                      Male Torso
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('FemaleTorso.obj')}
                    >
                      Female Torso
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('Arm_Right_Vertical.obj')}
                    >
                      Arm
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('Right_Hand_Vertical4.obj')}
                    >
                      Hand
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('MaleLeg_Right.obj')}
                    >
                      Leg
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* Bottom Toolbar */}
          <div className="flex justify-between canvas-bottom-element items-center mb-4git a mt-4">
            <div className="flex my-1">
              <CanvasIcon icon={<MdSaveAlt size={20} />} text="Save" onClick={() => saveTattooDesign()} />
              <CanvasIcon icon={<FaCamera />} text="Screenshot" onClick={saveCanvasAsImage} />
            </div>
            <div className="flex items-center">
              <CanvasIcon icon={<FaShare />} text="Share" onClick={shareTattoo} />
            </div>
          </div>
        </div>
      </div>

      {/* Upgrade Prompt */}
      {showUpgradePrompt && (
      <UpgradePrompt 
        onClose={() => setShowUpgradePrompt(false)}
        userId={user.id}
      />
    )}
      
      {showSignupModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowSignupModal(false)}>X</button>
            <Signup onClose={() => setShowSignupModal(false)} />
          </div>
        </div>
      )}

      {/* Pro Model Upgrade Prompt */}
      {showProModelUpgradePrompt && (
        <ProModelUpgradePrompt onClose={() => setShowProModelUpgradePrompt(false)} />
      )}

      {/* Saving Spinner */}
      {isSaving && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="spinner"></div>
        </div>
      )}

      {/* Confirm Save Before Share Modal */}
      {showConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-semibold mb-4">
              You must save your design before sharing. Would you like to save now?
            </h2>
            <div className="flex justify-center">
              <button
                className="bg-rose-500 hover:bg-rose-600 text-white px-4 py-2 rounded"
                onClick={handleConfirmSave}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 ml-2 rounded"
                onClick={() => setShowConfirmModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Share Modal */}
      {shareUrl && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
          <div className="bg-white flex gap-2 p-6 items-center rounded shadow-lg relative">
            <button
              onClick={() => setShareUrl(null)}
              className="absolute top-1 right-2 text-gray-600 hover:text-gray-900"
            >
              ✕
            </button>
            <p>Share your design:</p>
            <FacebookShareButton
              url={shareUrl}
              quote="Check out this tattoo I made!"
              className="flex items-center"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              url={shareUrl}
              title="Check out this tattoo I made!"
              className="flex items-center"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton
              url={shareUrl}
              title="Check out this tattoo I made!"
              className="flex items-center"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <button onClick={() => handleCopyLink(shareUrl)}>
              <FaLink size={24} className="flex items-center text-gray-500 hover:text-rose-500" />
            </button>
          </div>
        </div>
      )}

      {/* Text Input Modal with Preview */}
      {showTextModal && (
        <div className="modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="modal-content bg-white p-4 rounded relative">
            <button className="absolute top-2 right-2 text-xl" onClick={() => setShowTextModal(false)}>×</button>
            <h3 className="mb-4 text-lg font-semibold">Add Text</h3>
            <div className="mb-2">
              <label className="block text-sm">Text:</label>
              <input 
                type="text" 
                className="w-full border rounded p-1" 
                value={textInput} 
                onChange={(e) => setTextInput(e.target.value)} 
                placeholder="Enter your text" 
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm">Font:</label>
              <select
                className="w-full border rounded p-1"
                value={selectedFont}
                onChange={(e) => setSelectedFont(e.target.value)}
              >
                <option value="Arial" style={{ fontFamily: 'Arial' }}>Arial</option>
                <option value="'Times New Roman', serif" style={{ fontFamily: '"Times New Roman", serif' }}>Times New Roman</option>
                <option value="'Courier New', monospace" style={{ fontFamily: '"Courier New", monospace' }}>Courier New</option>
                <option value="Verdana" style={{ fontFamily: 'Verdana' }}>Verdana</option>
                <option value="'Comic Sans MS', cursive, sans-serif" style={{ fontFamily: '"Comic Sans MS", cursive, sans-serif' }}>Comic Sans</option>
                <option value="Georgia" style={{ fontFamily: 'Georgia' }}>Georgia</option>
                <option value="Impact" style={{ fontFamily: 'Impact' }}>Impact</option>
                <option value="Roboto" style={{ fontFamily: 'Roboto' }}>Roboto</option>
                {/* Tattoo/handwriting style fonts */}
                <option value="'Pacifico', cursive" style={{ fontFamily: 'Pacifico, cursive' }}>Pacifico</option>
                <option value="'Great Vibes', cursive" style={{ fontFamily: 'Great Vibes, cursive' }}>Great Vibes</option>
                <option value="'Dancing Script', cursive" style={{ fontFamily: 'Dancing Script, cursive' }}>Dancing Script</option>
                <option value="'Sacramento', cursive" style={{ fontFamily: 'Sacramento, cursive' }}>Sacramento</option>
                <option value="'Lobster', cursive" style={{ fontFamily: 'Lobster, cursive' }}>Lobster</option>
                <option value="'Satisfy', cursive" style={{ fontFamily: 'Satisfy, cursive' }}>Satisfy</option>
                <option value="'Indie Flower', cursive" style={{ fontFamily: 'Indie Flower, cursive' }}>Indie Flower</option>
                <option value="'Handlee', cursive" style={{ fontFamily: 'Handlee, cursive' }}>Handlee</option>
                {/* You can add additional fonts here */}
              </select>
            </div>
            <div className="mb-2">
              <label className="block text-sm">Font Size:</label>
              <input 
                type="number" 
                className="w-full border rounded p-1" 
                value={fontSize} 
                onChange={(e) => setFontSize(parseInt(e.target.value))} 
                min="10"
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm">Text Color:</label>
              <input 
                type="color" 
                className="w-full" 
                value={textColor} 
                onChange={(e) => setTextColor(e.target.value)} 
              />
            </div>
            {/* Preview of the text */}
            {textInput && (
              <div className="mb-2">
                <label className="block text-sm">Preview:</label>
                <img 
                  src={createTextImage(textInput, selectedFont, fontSize, textColor)} 
                  alt="Text Preview" 
                  style={{ maxWidth: '100%', border: '1px solid #ccc', padding: '5px' }} 
                />
              </div>
            )}
            <div className="flex justify-end">
              <button 
                className="bg-rose-500 hover:bg-rose-600 text-white px-4 py-2 rounded mr-2"
                onClick={() => {
                  if (!textInput) {
                    alert('Please enter some text');
                    return;
                  }
                  const textImageUrl = createTextImage(textInput, selectedFont, fontSize, textColor);
                  setTattooTextures(prev => {
                    const newArr = [...prev, textImageUrl];
                    setSelectedDecalIndex(newArr.length - 1);
                    return newArr;
                  });
                  setTattooPlacements(prev => [...prev, createCenterPlacement()]);
                  setTextInput('');
                  setShowTextModal(false);
                }}
              >
                Add
              </button>
              <button 
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
                onClick={() => setShowTextModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateCanvas;
