// Dashboard.jsx

import React, { useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

// Charts
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar, Pie, Line } from 'react-chartjs-2';

// Context
import { AuthContext } from './AuthContext';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const Dashboard = () => {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const [currentUTC, setCurrentUTC] = useState('');

  const fetchDashboardData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://koi-2028.onrender.com/dashboard-metrics');
      console.log('Raw metrics:', response.data);
      setMetrics(response.data);
      const utcString = new Date().toUTCString();
      setCurrentUTC(utcString);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to load dashboard data.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    if (user && user.is_admin) {
      fetchDashboardData();
    }
  }, [user]);

  if (!user || !user.is_admin) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-lg font-semibold">Loading dashboard...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-red-600">
        {error}
      </div>
    );
  }

  if (!metrics) return null;

  // -----------------------------------
  // CHART DATA CONFIG
  // -----------------------------------

  // ----------------
  // 1) User Distribution Pie
  // ----------------
  const userPieData = {
    labels: ['Free Users', 'Premium Users'],
    datasets: [
      {
        data: [
          metrics.user_metrics.free_users,
          metrics.user_metrics.premium_users,
        ],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  // ----------------
  // 2) Designs Per Day Bar
  // ----------------
  const designsBarData = {
    labels: metrics.design_metrics.designs_per_day.map((d) => d.date),
    datasets: [
      {
        label: 'Designs Per Day',
        data: metrics.design_metrics.designs_per_day.map((d) => d.count),
        backgroundColor: '#FFCE56',
      },
    ],
  };

  // ----------------
  // 3) User Retention Bar
  // ----------------
  const retentionBarData = {
    labels: metrics.user_retention.dates.map((day) => `Day ${day}`),
    datasets: [
      {
        label: 'Retention Rate (%)',
        data: metrics.user_retention.retention_rates,
        backgroundColor: '#4bc0c0',
      },
    ],
  };

  // ----------------
  // 4) User Growth Over Time (Line) - Filter + Sort
  // ----------------

  // (A) Filter out invalid or missing dates
  const validUserGrowth = metrics.user_growth.filter((entry) => {
    if (!entry.date) return false; // exclude empty dates
    const dateObj = new Date(entry.date);
    return !isNaN(dateObj.getTime()); // exclude "Invalid Date"
  });

  // (B) Sort by date in ascending (chronological) order
  const sortedUserGrowth = validUserGrowth.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  // For debugging: log the sorted data
  console.log('Sorted user growth data:', sortedUserGrowth);

  // (C) Construct the chart data
  const userGrowthLineData = {
    labels: sortedUserGrowth.map((data) => data.date),
    datasets: [
      {
        label: 'New Users',
        data: sortedUserGrowth.map((data) => data.new_users),
        borderColor: '#42A5F5',
        backgroundColor: 'rgba(66, 165, 245, 0.2)',
        fill: true,
      },
    ],
  };

  return (
    <div className="mx-auto p-4 max-w-screen-xl ml-16">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl md:text-3xl font-bold">Application Dashboard</h1>
        <button
          onClick={fetchDashboardData}
          className="px-4 py-2 bg-rose-500 text-white rounded-lg hover:bg-rose-600 focus:outline-none"
        >
          Refresh
        </button>
      </div>
      {/*
        =====================================================
        1) Key User Metrics in a compact grid
        =====================================================
      */}
      <section className="mb-4">
        <h2 className="text-xl font-semibold mb-2">User Metrics</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
          <div className="bg-blue-100 p-2 rounded shadow text-sm">
            <p className="text-gray-600">Total Users</p>
            <p className="font-bold">{metrics.user_metrics.total_users}</p>
          </div>
          <div className="bg-pink-100 p-2 rounded shadow text-sm">
            <p className="text-gray-600">Premium Users</p>
            <p className="font-bold">{metrics.user_metrics.premium_users}</p>
          </div>
          <div className="bg-green-100 p-2 rounded shadow text-sm">
            <p className="text-gray-600">Free Users</p>
            <p className="font-bold">{metrics.user_metrics.free_users}</p>
          </div>
          <div className="bg-yellow-100 p-2 rounded shadow text-sm">
            <p className="text-gray-600">New (Last 30 Days)</p>
            <p className="font-bold">
              {metrics.user_metrics.new_users_last_month}
            </p>
          </div>
        </div>

        {/* Additional Admin Metrics */}
        <div className="mt-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
          <div className="bg-purple-100 p-2 rounded shadow text-sm">
            <p className="text-gray-600">Monthly Active</p>
            <p className="font-bold">
              {metrics.user_metrics.monthly_active_users}
            </p>
          </div>
          <div className="bg-blue-50 p-2 rounded shadow text-sm">
            <p className="text-gray-600">Viral Coefficient</p>
            <p className="font-bold">
              {/* {metrics.user_metrics.viral_coefficient.toFixed(2)} */}
              ToDo
            </p>
          </div>
          <div className="bg-red-100 p-2 rounded shadow text-sm">
            <p className="text-gray-600">Churn Rate</p>
            <p className="font-bold">
              {metrics.user_metrics.churn_rate.toFixed(2)}%
            </p>
          </div>
          <div className="bg-indigo-100 p-2 rounded shadow text-sm">
            <p className="text-gray-600">Conversion Rate</p>
            <p className="font-bold">
              {metrics.user_metrics.conversion_rate.toFixed(2)}%
            </p>
          </div>
        </div>
      </section>

      <section className="mb-4">
        <div className="flex justify-between items-center mb-2">
            <h2 className="text-xl font-semibold">Today’s Metrics</h2>
            <span className="text-sm text-gray-500">
              Current UTC: {currentUTC}
            </span>
          </div>
        {metrics.today_metrics && (
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <div className="bg-orange-100 p-2 rounded shadow text-sm">
              <p className="text-gray-600">New Users Today</p>
              <p className="font-bold">{metrics.today_metrics.new_users_today}</p>
            </div>
            <div className="bg-blue-100 p-2 rounded shadow text-sm">
              <p className="text-gray-600">Active Users Today</p>
              <p className="font-bold">{metrics.today_metrics.active_users_today}</p>
            </div>
            <div className="bg-green-100 p-2 rounded shadow text-sm">
              <p className="text-gray-600">Designs Created Today</p>
              <p className="font-bold">{metrics.today_metrics.designs_created_today}</p>
            </div>
          </div>
        )}
      </section>

      {/*
        =====================================================
        2) Two Charts Side-by-Side: 
           - User Distribution Pie
           - Designs Per Day Bar
        =====================================================
      */}
      <section className="mb-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="bg-white p-2 rounded shadow">
          <Pie
            data={userPieData}
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'top' },
                title: { display: true, text: 'User Distribution' },
              },
            }}
          />
        </div>
        <div className="bg-white p-2 rounded shadow">
          <Bar
            data={designsBarData}
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'top' },
                title: { display: true, text: 'Designs / Day' },
              },
            }}
          />
        </div>
      </section>

      {/*
        =====================================================
        3) Engagement, Top Design, & Revenue in a row 
        =====================================================
      */}
      <section className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white p-2 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Engagement</h2>
          <div className="text-sm mb-2 bg-blue-50 p-2 rounded">
            <p className="text-gray-600">Total Likes</p>
            <p className="font-bold">
              {metrics.engagement_metrics.total_likes}
            </p>
          </div>
          <div className="text-sm bg-pink-50 p-2 rounded">
            <p className="text-gray-600">Total Favorites</p>
            <p className="font-bold">
              {metrics.engagement_metrics.total_favorites}
            </p>
          </div>
        </div>
        <div className="bg-white p-2 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Top Design</h2>
          {metrics.design_metrics.most_liked_design ? (
            <div className="text-sm bg-pink-50 p-2 rounded">
              <p className="text-gray-600">Most Liked Design</p>
              <p className="font-bold">
                ID: {metrics.design_metrics.most_liked_design.id}
              </p>
              <p>
                Likes: {metrics.design_metrics.most_liked_design.likes_count}
              </p>
            </div>
          ) : (
            <p className="italic text-sm">No data available</p>
          )}
        </div>
        <div className="bg-white p-2 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Revenue</h2>
          <div className="text-sm bg-yellow-50 p-2 rounded">
            <p className="text-gray-600">Estimated Monthly</p>
            <p className="font-bold">
              ${metrics.revenue_metrics.estimated_monthly_revenue}
            </p>
          </div>
        </div>
      </section>

      {/*
        =====================================================
        4) Retention & Growth side by side
        =====================================================
      */}
      <section className="mb-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="bg-white p-2 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">User Retention</h2>
          <Bar
            data={retentionBarData}
            options={{
              responsive: true,
              scales: {
                y: {
                  title: { display: true, text: 'Rate (%)' },
                  beginAtZero: true,
                  max: 100,
                },
              },
              plugins: {
                legend: { display: false },
                title: {
                  display: true,
                  text: 'Retention: Day 1, 7, 30',
                },
              },
            }}
          />
        </div>
        <div className="bg-white p-2 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">User Growth Over Time</h2>
          <Line
            data={userGrowthLineData}
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'top' },
                title: {
                  display: true,
                  text: 'Daily New User Signups',
                },
              },
            }}
          />
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
