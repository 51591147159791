import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import '@babylonjs/loaders/OBJ/objFileLoader';
import { FaEdit, FaShare, FaLink, FaTrash } from 'react-icons/fa';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

const UserModal = ({
  isOpen,
  onClose,
  modelUrl,
  images,
  positions,
  rotations,
  scales,
  placements, // placement data for decals (if any)
  useDecals = true, // if true, use decal approach; otherwise, use dynamic texture
  tattoo,
  handleEditClick,
  handleShareClick,
  handleDeleteClick,
  username,
  id,
}) => {
  const canvasRef = useRef(null);
  const engineRef = useRef(null);
  const sceneRef = useRef(null);
  const dynamicTextureRef = useRef(null);
  const modelRef = useRef(null);
  const decalMeshesRef = useRef([]); // stores decal meshes for later disposal
  const shareRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [shareVisible, setShareVisible] = useState(false);

  const uniqueTattooUrl = `${window.location.origin}/#/userpage/${username}/${id}`;

  const handleShareClickInternal = useCallback((e) => {
    e.stopPropagation();
    setShareVisible((prev) => !prev);
  }, []);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(uniqueTattooUrl).then(() => {
      alert('Link copied to clipboard!');
    });
  }, [uniqueTattooUrl]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShareVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // ----------------------------------------------------------------
  // Auto-scale & Center Helper (model rotation removed)
  // ----------------------------------------------------------------
  function autoScaleMesh(mesh) {
    if (mesh.getChildren && mesh.getChildren().length > 0) {
      const merged = BABYLON.Mesh.MergeMeshes(
        mesh.getChildren(true),
        true,
        true,
        undefined,
        false,
        true
      );
      if (merged) {
        mesh = merged;
      }
    }
    mesh.computeWorldMatrix(true);
    const bbInfo = mesh.getBoundingInfo();
    const sizeVec = bbInfo.boundingBox.extendSizeWorld;
    const maxDim = Math.max(sizeVec.x, sizeVec.y, sizeVec.z);
    const desiredSize = 2; // adjust as needed
    const scaleFactor = desiredSize / maxDim;
    mesh.scaling.setAll(scaleFactor);

    // Removed the 90° rotation here.
    mesh.computeWorldMatrix(true);

    // Recenter the model so the origin is (0,0,0)
    const bb2 = mesh.getBoundingInfo();
    const center = bb2.boundingBox.centerWorld.clone();
    mesh.position.subtractInPlace(center);
    mesh.computeWorldMatrix(true);

    return mesh;
  }

  // ----------------------------------------------------------------
  // Helper: Create Decal Material
  // ----------------------------------------------------------------
  const createDecalMaterial = (textureURL, scene) => {
    const decalMat = new BABYLON.StandardMaterial("decalMat", scene);
    decalMat.diffuseTexture = new BABYLON.Texture(textureURL, scene);
    decalMat.diffuseTexture.hasAlpha = true;
    decalMat.useAlphaFromDiffuseTexture = true;
    decalMat.needDepthPrePass = true;
    decalMat.separateCullingPass = true;
    decalMat.backFaceCulling = false;
    decalMat.transparencyMode = BABYLON.Material.MATERIAL_ALPHABLEND;
    decalMat.alphaMode = BABYLON.Engine.ALPHA_COMBINE;
    decalMat.zOffset = -1;
    return decalMat;
  };

  // ----------------------------------------------------------------
  // Updated: Decal Approach
  //
  // Since we removed the model’s 90° rotation, we now adjust each decal
  // so they still appear in the same relative location. We do this by
  // pre-rotating the decal’s position, normal, and its own rotation by +90°.
  // ----------------------------------------------------------------
  const applyDecalApproach = () => {
    if (!sceneRef.current || !modelRef.current) return;
    // Dispose any previous decals.
    decalMeshesRef.current.forEach((d) => d.dispose());
    decalMeshesRef.current = [];

    // Set the angle offset (+90°)
    const angleOffset = Math.PI / 2;
    // Helper function to rotate a vector around the Y axis.
    const rotateVector = (vec, angle) => {
      return new BABYLON.Vector3(
        vec.x * Math.cos(angle) - vec.z * Math.sin(angle),
        vec.y,
        vec.x * Math.sin(angle) + vec.z * Math.cos(angle)
      );
    };

    // Use placements if provided; otherwise, fall back to legacy positions.
    const decalData = placements && placements.length > 0 ? placements : positions;

    images.forEach((url, i) => {
      const data = decalData[i] || {};
      let pos, norm, rotDeg, scl;
      if (placements && placements.length > 0) {
        pos = data.position || { x: 0, y: 1, z: 0 };
        norm = data.normal || { x: 0, y: 0, z: -1 };
        rotDeg = data.rotation || 0;
        scl = data.scale || 1;
      } else {
        pos = { x: data.x || 0, y: data.y || 1, z: 0 };
        norm = { x: 0, y: 0, z: -1 };
        rotDeg = rotations[i] || 0;
        scl = scales[i] || 1;
      }
      // Adjust position and normal by applying the +90° rotation.
      const adjustedPos = rotateVector(new BABYLON.Vector3(pos.x, pos.y, pos.z), angleOffset);
      const adjustedNorm = rotateVector(new BABYLON.Vector3(norm.x, norm.y, norm.z), angleOffset);
      // Also adjust the decal’s own rotation (in radians).
      const adjustedAngle = (rotDeg * Math.PI) / 180;
      // Create a decal mesh with a thin z-size (0.1) scaled by the provided factor.
      const decalSize = new BABYLON.Vector3(0.3, 0.3, 0.1).scale(scl);
      const decalMesh = BABYLON.MeshBuilder.CreateDecal(
        "tattooDecal",
        modelRef.current,
        {
          position: adjustedPos,
          normal: adjustedNorm,
          size: decalSize,
          angle: adjustedAngle,
        }
      );
      const decalMat = createDecalMaterial(url, sceneRef.current);
      decalMesh.material = decalMat;
      decalMeshesRef.current.push(decalMesh);
    });
  };

  // ----------------------------------------------------------------
  // Dynamic Texture Approach (Legacy)
  // ----------------------------------------------------------------
  const applyDynamicTextureApproach = (mesh, textures, positions, rotations, scales) => {
    if (!dynamicTextureRef.current) return;
    const ctx = dynamicTextureRef.current.getContext();
    ctx.clearRect(0, 0, 2048, 2048);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, 2048, 2048);
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";

    textures.forEach((textureURL, i) => {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.onload = () => {
        const maxSize = 400;
        const aspect = image.width / image.height;
        let drawWidth = maxSize;
        let drawHeight = maxSize;
        if (aspect > 1) {
          drawHeight = drawWidth / aspect;
        } else {
          drawWidth = drawHeight * aspect;
        }
        const { x, y } = positions[i] || { x: 0, y: 0 };
        const rotation = rotations[i] || 0;
        const scale = scales[i] || 1;
        ctx.save();
        ctx.translate(x + drawWidth / 2, y + drawHeight / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.scale(scale, scale);
        ctx.drawImage(image, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight);
        ctx.restore();
        dynamicTextureRef.current.update();
      };
      image.onerror = (error) => {
        console.error("Failed to load image:", textureURL, error);
      };
      image.src = textureURL;
    });

    if (!mesh.material) {
      const material = new BABYLON.StandardMaterial("tattooMaterial", sceneRef.current);
      material.diffuseTexture = dynamicTextureRef.current;
      material.backFaceCulling = false;
      mesh.material = material;
    } else {
      mesh.material.diffuseTexture = dynamicTextureRef.current;
    }
  };

  useEffect(() => {
    if (!isOpen || !canvasRef.current) return;

    const initializeBabylon = () => {
      if (!engineRef.current) {
        engineRef.current = new BABYLON.Engine(canvasRef.current, true);
      }
      if (!sceneRef.current) {
        sceneRef.current = new BABYLON.Scene(engineRef.current);
        const camera = new BABYLON.ArcRotateCamera(
          "Camera",
          new BABYLON.Vector3(0, 1, -10),
          new BABYLON.Vector3(0, 1, 0),
          sceneRef.current
        );
        camera.attachControl(canvasRef.current, true);
        new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), sceneRef.current);

        const preventScroll = (event) => {
          if (canvasRef.current && event.target === canvasRef.current) {
            event.preventDefault();
          }
        };
        document.addEventListener("wheel", preventScroll, { passive: false });

        // Create dynamic texture if not using decals.
        if (!useDecals) {
          dynamicTextureRef.current = new BABYLON.DynamicTexture(
            "dynamicTexture",
            { width: 2048, height: 2048 },
            sceneRef.current
          );
        }
        sceneRef.current.clearColor = new BABYLON.Color4(0.1255, 0.1333, 0.1451, 1.0);
      } else {
        sceneRef.current.clearColor = new BABYLON.Color4(0.1255, 0.1333, 0.1451, 1.0);
      }

      setIsLoading(true);

      // Import the model.
      BABYLON.SceneLoader.ImportMesh(
        "",
        "assets/",
        modelUrl,
        sceneRef.current,
        (meshes) => {
          if (!meshes || meshes.length === 0) {
            console.error("No meshes loaded!");
            setIsLoading(false);
            return;
          }
          // Auto-scale and center the mesh (model rotation removed)
          modelRef.current = autoScaleMesh(meshes[0]);

          // Apply decals or dynamic texture based on useDecals.
          if (useDecals) {
            applyDecalApproach();
          } else {
            applyDynamicTextureApproach(modelRef.current, images, positions, rotations, scales);
          }

          setIsLoading(false);
          sceneRef.current.createDefaultCameraOrLight(true, true, true);
          if (sceneRef.current.activeCamera) {
            sceneRef.current.activeCamera.alpha += Math.PI;
          }
        }
      );

      engineRef.current.runRenderLoop(() => {
        if (sceneRef.current) {
          sceneRef.current.render();
        }
      });

      const handleResize = () => {
        if (engineRef.current) {
          engineRef.current.resize();
        }
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        if (engineRef.current) {
          engineRef.current.stopRenderLoop();
          engineRef.current.dispose();
          engineRef.current = null;
          sceneRef.current = null;
        }
      };
    };

    const cleanup = initializeBabylon();
    return cleanup;
  }, [isOpen, modelUrl, images, positions, rotations, scales, placements, useDecals]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10">
          <div className="spinner"></div>
        </div>
      )}
      <div className="bg-white rounded-lg overflow-hidden relative w-[80vw] h-[80vh] max-w-4xl flex">
        <button className="absolute top-3 right-3 close-button z-10 text-white bg-gray-800 p-1 rounded" onClick={onClose}>
          X
        </button>
        <div className="flex-grow relative">
          <canvas ref={canvasRef} className="w-full h-full" />
        </div>
        <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex flex-col items-center space-y-4">
          <button
            className="flex items-center mr-4 text-white text-2xl hover:text-rose-500"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(tattoo);
            }}
          >
            <FaEdit className="mr-2" />
          </button>
          <div className="relative" ref={shareRef}>
            <button
              className="flex items-center mr-4 text-white text-2xl hover:text-rose-500"
              onClick={handleShareClickInternal}
            >
              <FaShare className="mr-2" />
            </button>
            {shareVisible && (
              <div className="absolute bottom-full transform right-1/4 mb-2 bg-white shadow-md p-2 rounded-md flex gap-2 z-50">
                <FacebookShareButton url={uniqueTattooUrl} quote="Check out this tattoo!" className="flex items-center">
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={uniqueTattooUrl} title="Check out this tattoo!" className="flex items-center">
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={uniqueTattooUrl} title="Check out this tattoo!" className="flex items-center">
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <button onClick={handleCopyLink} className="flex items-center text-gray-500 hover:text-rose-500">
                  <FaLink size={24} className="mr-2" />
                </button>
              </div>
            )}
          </div>
          <button
            className="flex items-center mr-4 text-white text-2xl hover:text-rose-500"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(tattoo.id);
            }}
          >
            <FaTrash className="mr-2" />
          </button>
        </div>
      </div>
      <style>{`
        .spinner {
          width: 50px;
          height: 50px;
          border: 6px solid rgba(255, 255, 255, 0.3);
          border-top-color: #fff;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default UserModal;