import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FaUserCircle, FaBell } from 'react-icons/fa';
import { IoIosCreate } from 'react-icons/io';
import SkullIcon from './SkullIcon';
import { BsFillLightningFill } from 'react-icons/bs';
import Login from './Login';
import Signup from './Signup';

const PhoneNavigation = () => {
  const { user, logout } = useContext(AuthContext);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleUserIconClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="phone-navigation  fixed top-0 left-0 w-full flex items-center justify-between bg-gray-900 text-white shadow-lg z-50 p-1">
      {/* Navigation icons */}
      <div className="flex items-center space-x-1">
        <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
        <div className="phone-icon">
          <SkullIcon size={100} />
        </div>
        </NavLink>
        <Divider />
        <NavLink to="/create" className={({ isActive }) => (isActive ? 'active' : '')}>
          <NavBarIcon icon={<IoIosCreate size="32" />} text="Create" />
        </NavLink>
        <Divider />
        {user && (
          <NavLink to={`/userpage/${user.username}`} className={({ isActive }) => (isActive ? 'active' : '')}>
            <NavBarIcon icon={<BsFillLightningFill size="32" />} text="Designs" />
          </NavLink>
        )}
      </div>

      {/* User actions */}
      <div className="flex items-center space-x-2">
        
        {user ? (
          <>
          <FaBell className="cursor-pointer top-navigation-icon text-[1.375rem]"/>
            {user.user_avatar ? (
              <img
                src={user.user_avatar}
                alt="User Avatar"
                className="rounded-full cursor-pointer"
                style={{ width: '35px', height: '35px', objectFit: 'cover' }}
                onClick={handleUserIconClick}
              />
            ) : (
              <FaUserCircle
                className="cursor-pointer hover:text-gray-300 "
                onClick={handleUserIconClick}
              />
            )}
            {showDropdown && (
              <div
                ref={dropdownRef}
                className="absolute right-2 top-12 mt-2 w-48 bg-white text-gray-800 border border-gray-300 rounded-lg shadow-lg z-50"
              >
                <ul className="py-2">
                  <li
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => window.location.href = `/#/userpage/${user.username}`}
                  >
                    {user.username}
                  </li>
                  <li
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => window.location.href = '/#/subscribe'}
                  >
                    <span className="mr-2 text-yellow-500">💎</span>
                    Premium
                  </li>
                  <li
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => window.location.href = '/#/settings'}
                  >
                    Settings and Privacy
                  </li>
                  <li
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => window.location.href = '/#/support'}
                  >
                    Help
                  </li>
                  <li
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    onClick={logout}
                  >
                    Sign Out
                  </li>
                </ul>
              </div>
            )}
          </>
        ) : (
          <>
            <button
              onClick={() => setShowLogin(true)}
              className="px-4 py-2 text-sm font-semibold bg-rose-500 rounded-lg hover:bg-rose-600"
            >
              Login
            </button>
            <button
              onClick={() => setShowSignup(true)}
              className="px-4 py-2 text-sm font-semibold bg-rose-500 rounded-lg hover:bg-rose-600"
            >
              Signup
            </button>
          </>
        )}
      </div>

      {/* Login modal */}
      {showLogin && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowLogin(false)}>
              X
            </button>
            <Login onClose={() => setShowLogin(false)} />
          </div>
        </div>
      )}

      {/* Signup modal */}
      {showSignup && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowSignup(false)}>
              X
            </button>
            <Signup onClose={() => setShowSignup(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

const NavBarIcon = ({ icon, text }) => (
  <div className="phone-icon flex-col group" >
    {icon}
    {text && (
      <span className="sidebar-tooltip absolute mt-1 px-2 py-1 text-xs bg-gray-800 text-white rounded opacity-0 group-hover:opacity-100 transition-opacity">
        {text}
      </span>
    )}
  </div>
);

const Divider = () => <div className="h-full w-px bg-gray-600 mx-2"></div>;

export default PhoneNavigation;