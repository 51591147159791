import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';

// Define the free design reset period (5 days in milliseconds)
const FREE_DESIGN_RESET_PERIOD_MS = 60 * 60 * 1000;

// Helper to parse an ISO date string (trims extra fractional digits if needed)
const parseISODate = (isoString) => {
  if (!isoString) {
    console.log("No ISO date provided");
    return null;
  }
  const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3})\d*(.*)$/;
  const match = isoString.match(regex);
  if (match) {
    return new Date(match[1] + match[2]);
  }
  return new Date(isoString);
};

// Custom hook for countdown timer (updates every second)
const useCountdown = (targetTime) => {
  const calculateTimeLeft = () => {
    if (!targetTime) return null;
    const now = new Date();
    const diff = targetTime - now;
    if (diff <= 0) return null;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);
    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (!targetTime) return;
    const interval = setInterval(() => {
      const updated = calculateTimeLeft();
      setTimeLeft(updated);
      if (!updated) {
        clearInterval(interval);
      }
    }, 1000); // update every second
    return () => clearInterval(interval);
  }, [targetTime]);

  return timeLeft;
};

const UpgradePrompt = ({ onClose, userId }) => {
  const navigate = useNavigate();
  const [lastDesignCreationDate, setLastDesignCreationDate] = useState(null);
  const [loading, setLoading] = useState(true);

  // Always fetch the user details (lastDesignCreationDate) when the component mounts.
  useEffect(() => {
    axios
      .get(`https://koi-2028.onrender.com/users/${userId}`)
      .then((response) => {
        console.log("Fetched user details:", response.data);
        setLastDesignCreationDate(response.data.last_design_creation_date);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching user details:", err);
        setLoading(false);
      });
  }, [userId]);

  // Unconditionally compute targetTime and call useCountdown.
  const parsedDate = parseISODate(lastDesignCreationDate);
  const targetTime = parsedDate ? new Date(parsedDate.getTime() + FREE_DESIGN_RESET_PERIOD_MS) : null;
  const timeLeft = useCountdown(targetTime);

  // Do not render the modal until loading is complete and the countdown has a value.
  if (loading || !timeLeft) {
    return null;
  }

  const handleUpgradeClick = () => {
    navigate('/subscribe');
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg text-center relative max-w-md">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
          aria-label="Close"
        >
          <FaTimes size={20} />
        </button>
        <h2 className="text-3xl font-bold mb-4 text-gray-900">
          Unlock Unlimited Creativity
        </h2>
        <p className="text-lg mb-6 text-gray-700">
          You’ve hit the limit of free designs. You can create a free design in{' '}
          <strong>
            {/* {timeLeft.days} {timeLeft.days === 1 ? 'day' : 'days'}, {timeLeft.hours}{' '} */}
            {/* {timeLeft.hours === 1 ? 'hour' : 'hours'}, */}
            {timeLeft.minutes}{' '} {timeLeft.minutes === 1 ? 'minute' : 'minutes'}, and {timeLeft.seconds}{' '}
            {timeLeft.seconds === 1 ? 'second' : 'seconds'}
          </strong>.
        </p>
        <ul className="list-disc text-left ml-6 mb-6 text-gray-600">
          <li className="mb-2">⚡ Unlimited designs</li>
          <li className="mb-2">🌟 Access to premium models and tools</li>
          <li className="mb-2">💬 Priority customer support</li>
          <li className="mb-2">📈 Early access to new features</li>
        </ul>
        <button
          className="bg-rose-500 text-white px-6 py-3 rounded-full hover:bg-rose-400 mb-4 w-full font-semibold"
          onClick={handleUpgradeClick}
          disabled={!!timeLeft}
        >
          Upgrade to Pro
        </button>
        <p className="text-sm text-gray-500 mb-4">Starting at just $9.99/month</p>
      </div>
    </div>
  );
};

export default UpgradePrompt;
